import React from "react"
import Navbar from "./nav-bar"

const Sidebar = () => {
    return(
        <div>
            <Navbar/>
        </div>
    )
}

export default Sidebar