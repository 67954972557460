import React from "react"

const KiwanisCalendar = () => {
    return(
        <div>
            <iframe title="Kiwanis-house" src="https://calendar.google.com/calendar/embed?src=2v1icc8m0thf75o5p64o149igc%40group.calendar.google.com&ctz=Atlantic%2FFaroe&fbclid=IwAR0ixDk_mZ5ruv5DFpFWUfvBn_f8kfWupVUsOSbbpV5w__4Z-ph7LKzfGGs" width="400" height="400"></iframe>
        </div>
    )
}

export default KiwanisCalendar