import React from "react"
import {Link , navigate} from "gatsby"
import navbarStyles from "./navbar.module.scss"
import {isLoggedIn, logout } from "../services/auth"



export default () => {    
    return(        
    <div>
        <nav>        
            {isLoggedIn() ? (
                <ul>                        
                    <Link to="/bok"><li className={navbarStyles.navli}>Hvítabók</li></Link>
                    <Link to="/gavuhandan"><li className={navbarStyles.navli}>Gávuhandan</li></Link>
                    <Link to="/hugskot"><li className={navbarStyles.navli}>Hugskotskassi</li></Link>
                    <Link to="/nevndir"><li className={navbarStyles.navli}>Nevndir</li></Link>
                    <Link to="/limir"><li className={navbarStyles.navli}>Limalisti</li></Link>
                    <Link to="/reglar"><li className={navbarStyles.navli}>Leiðreglar</li></Link>
                
                    <Link to="/app/profile"><li>Profile</li></Link>
                    <a 
                    href="/"            
                    onClick={event => {
                        event.preventDefault()              
                        logout(() => navigate(`/app/login`))
                    }}          
                    >
                        Logout
                    </a>
                </ul>                        
            ) : 
            <ul>
                <Link to="/">
                    <li className={navbarStyles.navli}>Forsíða</li>
                </Link>
                <Link to="/samband">
                    <li className={navbarStyles.navli}>Samband</li>
                </Link>                
                <Link to="/um">
                    <li className={navbarStyles.navli}>Um okkum</li>
                </Link>
            </ul>   
            }                                                                             
        </nav> 
    </div>
    )   
}