import React from "react"
import Layout from "../components/layout"
import winnings from "../../static/img/2024_vinnarar.jpg"
// import harmonika from "../../static/img/Harmonika í Nólsoy.jpg"
export default () => (
    <Layout>
        <img src={winnings} alt="Harmonika og nógv fólk í Nólsoy"/>
        <h1>Kiwanis stuðlar børnum umvegis millum annað jólatræssølu og lutaseðlasølu á Ólavsøku</h1>        
        <h4>Tað eru 3. Kiwanis felagsskapir í Føroyum.</h4>
        <p>Kiwanis Tórshavn og Kiwanis Rósan í Havn, og Kiwanis Eysturoy, sum hevur heimstað í Gøtu. 
        <blockquote>Orðið “kiwanis” stavar úr indianskum máli og merkir “vit byggja.”</blockquote>
        <b>Nevndin í Kiwanis Tórshavn</b>
        </p>
    </Layout>
) 