import React from "react"

const Year = new Date().getFullYear();

const Footer = () => {
    return(
        <div>            
            <h4>
                &copy; {Year} Kiwanis Føroyar <br/>                
                <a href="https://www.hlweb.dk">Heimasíðu menning HL Web</a>
            </h4>

        </div>
    )
}

export default Footer