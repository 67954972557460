import React from "react"
import HeaderStyles from "./header.module.scss"

const Address = () => {
    return(
        <div >
            <h3 className={HeaderStyles.BlueFont}>
                Kiwanis <br/>
                Jøkulstræti 4 <br/>
                100 Tórshavn
            </h3>
        </div>
    )
}

export default Address