import React from "react"
import "../styles/index.scss"
import layoutStyles from "./layout.module.scss"
import PageHeader from "./header"
import Sidebar from "./sidebar"
import Aside from "./aside"
import Footer from "./footer"

const Layout = ({ children }) => (
    <div className={layoutStyles.container}>  
        <PageHeader /> 
        <div className={layoutStyles.Sidebar}>
            <Sidebar />
        </div>
        <div  className={layoutStyles.main}>
            {children}
        </div>
        <Aside />
        <Footer />
    </div>
)

export default Layout