import React from "react"
import Address from "./address"
import { StaticQuery, graphql , Link  } from 'gatsby'
import Logo from "../../static/Logo.jpg"

export default () => (
    <StaticQuery
        query={graphql`
        {
            allAirtable(filter: {table: {eq: "Web"}, data: {Name: {eq: "Logo"}}} ) {
                nodes {
                    recordId
                    data {
                        Name
                        Attachments {
                            url
                        }
                    }
                }
            }
        }
    `}
    render={ data => (        
        <header>
            <script src="https://kit.fontawesome.com/60117de434.js"></script>
            {/* {data.allAirtable.nodes.map(node => (
                <div key={node.recordId}>
                    <Link to="/"><img src={node.data.Attachments[0].url} alt={node.data.Name} height="262" width="400"/></Link>
                </div>
            ))} */}
            <Link to="/"><img src={Logo} alt={"Kiwanis logo"} height="262" width="400"/></Link>
            <Address/>
        </header>
    )}
    />
)