import React from "react"
import KiwanisCal from "./kiwanisCalendar"

const Aside = () => {
    return(
        <div>
            <aside>  
                <KiwanisCal />              
            </aside>
        </div>
    )
}

export default Aside;